<template>
  <div>
    <b-card>
      <div class="mb-5">
        <b-card-title
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="UserIcon"
            size="21"
            class="mr-1"
          />
          Шаблон
        </b-card-title>
        <templates-list />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import TemplatesList from './TemplatesList.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    TemplatesList,
  },
  data() {
    return {
      template: '',
    }
  },
  computed: {
    ...mapGetters({
      templates: 'masters/getTemplates',
    }),
  },
  async mounted() {
    await this.fetchTemplates()
  },
  methods: {
    ...mapActions({
      fetchTemplates: 'masters/fetchTemplates',
      changeTemplate: 'masters/changeTemplate',
    }),
  },
}
</script>

<style lang="scss">
.select2-selection__arrow {
  display: none;
}
</style>
