<template>
  <b-modal
    id="edit-template-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование шаблона «${template.name}»`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-1">
        <div>
          <b-form-group
            label="Название шаблона"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="editTemplate.name"
              v-validate="'required'"
              disabled="true"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Описание"
            label-for="content_template"
          >
            <CKEditor
              v-model="editTemplate.content"
              :config="editorConfig"
              name="content"
            />
          </b-form-group>
        </div>
        <b-form-group
          v-for="(variable, index) in editTemplate.variable_list"
          :key="index"
          :label="`Переменная ${variable}`"
          :label-for="`variables${variable}`"
        >
          <Select2
            :id="`variables${variable}`"
            v-model="editTemplate.variables[variable]"
            :options="variables"
            :settings="{
              multiple: false,
              minimumResultsForSearch: Infinity
            }"
            name="variables"
          />
          <small
            v-show="errors.has('variables')"
            class="text-danger"
          >
            {{ errors.first('variables') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Компании, к которым привязан шаблон"
          label-for="companies"
        >
          <Select2
            id="companies"
            v-model="editTemplate.companies"
            :options="companies"
            :settings="{multiple: true}"
            name="companies"
          />
          <small
            v-show="errors.has('companies')"
            class="text-danger"
          >
            {{ errors.first('companies') }}
          </small>
        </b-form-group>
        <div>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="editTemplate.default"
              switch
            />
            <div>
              Шаблон по умолчанию
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormCheckbox, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Select2 from 'v-select2-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CKEditor from 'ckeditor4-vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import AdminFeedback from '@/api/http/models/admin/feedback/AdminFeedbackTemplate'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    Select2,
    CKEditor: CKEditor.component,
  },
  mixins: [mixinErrorsParse],
  props: {
    templateId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Редактирование шаблона',
    },
  },
  data() {
    return {
      visible: false,
      editTemplate: {},
      template: {},
      companies: [],
      variables: [],
      editorConfig: {
        readOnly: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const responseTemplate = await AdminFeedback.getTemplate(this.templateId)

    this.template = responseTemplate.data || {}

    const responseCompanies = await AdminFeedback.getCompanies()

    this.companies = responseCompanies.data.map(company => ({
      id: company.id,
      text: company.name,
    }))

    const responseTemplateVariables = await AdminFeedback.getTemplateVariables()

    this.variables = Object.entries(responseTemplateVariables.data).map(variable => ({
      id: variable[0],
      text: variable[1],
    }))

    this.template.variable_list.forEach(property => {
      if (!this.template.variables[property]) {
        this.template.variables[property] = null
      }
    })

    await this.prepareTemplateObject()

    this.visible = true
  },
  methods: {
    prepareTemplateObject() {
      this.editTemplate = JSON.parse(JSON.stringify(this.template))
    },

    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    prepareCompaniesArray() {
      this.editTemplate.companies = this.editTemplate.companies.map(company => Number(company))
    },

    async handleSubmit() {
      this.prepareCompaniesArray()
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await AdminFeedback.update(this.templateId, this.editTemplate)

        if (response.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Шаблон обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-template-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении шаблона',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-template-popup')
        })
      }
    },
  },
}
</script>
