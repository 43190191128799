<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-lg-8">
          Название шаблона
        </div>
        <div class="col-lg-4 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="template in templates"
        :key="template.id"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-8">
                {{ template.name }}
              </div>
              <div class="col-12 col-lg-4">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(template)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-template-popup
      v-if="showEditPopup && currentTemplateId"
      :key="currentTemplateId"
      :template-id="currentTemplateId"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import AdminFeedback from '@/api/http/models/admin/feedback/AdminFeedbackTemplate'
import EditTemplatePopup from './EditTemplatePopup.vue'

export default {
  components: {
    BButton,
    EditTemplatePopup,
  },
  data() {
    return {
      templates: [],
      showEditPopup: false,
      showDeletePopup: false,
      currentTemplateId: null,
    }
  },
  async created() {
    const response = await AdminFeedback.getTemplates()

    if (response.data) {
      this.templates = response.data
    }
  },
  methods: {
    openEditPopup(template) {
      this.showEditPopup = true
      this.currentTemplateId = template.id
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentTemplateId = null
    },
  },
}
</script>
